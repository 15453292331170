.Navbar {
  // position: fixed;
  // bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    text-decoration: none;
    color: inherit;
  }
  .nav ul {
    display: flex;
    li {
      margin: 1rem;
      list-style: none;
    }
  }
  .icon {
    font-size: 2.5rem;
    margin: 1rem;
    color: rgb(0, 4, 118);
  }
  @media (min-width: 1000px) {
    .Navbar {
      font-size: 1.5rem;
    }
  }
}
