.Header {
  p {
    margin: 1rem;
    text-align: center;
  }
}

.Logo {
  margin: 2rem auto;
  width: 50%;
  fill: #801c29;
  @media (min-width: 750px) {
    width: 60%;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
}
