.Content {
  flex-flow: column, nowrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: "Roboto";
  color: rgb(91, 21, 21);

  .Video{
    display: flex;
    justify-content: center;
    margin: 2rem;
  }

  ul {
    padding: 0;
    margin: auto;
  }
  li {
    margin: 1rem;
    list-style: none;
    text-align: center;
    a {
      font-size: 1.5rem;
      font-family: "Roboto", sans-serif;
      color: rgb(0, 4, 118);
      text-decoration: none;
      &:hover {
        color: rgb(249, 165, 22);
      }
    }
  }
}
